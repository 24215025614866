import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";


import * as classes from "./header.module.scss";
import ContactForm from '../contact/contact_form';

const Header = () => {
  let { t, i18n } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [defaultLangauge, setDefaultLangauge] = useState({});
  const menuBarData = [
    {
      name: `${t('products')}`,
      description: `${t('our_suite_of_state_of_the_art_assessment_tools')}`,
      link: "/analyse",
      primaryCategories: [
        {
          name: `${t('platform')}`,
          description: `${t('evaluate_your_candidates_in_the_easiest_way')}`,
          link: "/plateforme",
        },
        {
          name: "Extension",
          description: `${t('a_revolutionary_solution_for_creating_secure')}`,
          link: "/extension",
        },
        {
          name: "Lockdown Browser",
          description: `${t('prevent_your_candidates_from_accessing_web')}`,
          link: "/lockdown-browser",
        },
      ],
      secondaryCategories: [
        {
          name: `${t('evaluation')}`,
          description: `${t('move_from_idea_to_action_with')}`,
          link: "/évaluation",
        },
        {
          name: "Analyse",
          description: `${t('recruit_top_talent_with_our_state')}`,
          link: "/analyse",
        },
      ],
    },
    {
      name: `${t('exclusives')}`,
      description: `${t('leveraging_mereos_powerful_technology')}`,
      link: "/expérience-candidat",
      primaryCategories: [
        {
          name: "Candidate Friendly",
          description: `${t('offer_your_candidates_a_unique_experience_they_remember')}`,
          link: "/expérience-candidat",
        },
        {
          name: "Support",
          description: `${t('reach_your_goals_more_easily_with_mereos')}`,
          link: "/support",
        },
        {
          name: `${t('scalability')}`,
          description: `${t('organize_large_scale_recruitment')}`,
          link: "/scalabilité",
        },
      ],
      secondaryCategories: [
        {
          name: `${t('flexibility')}`,
          description: `${t('with_over_30_security_features')}`,
          link: "/flexibilité",
        },
        {
          name: `${t('data_protection')}`,
          description: `${t('keep_full_control_of_your_candidate_data')}`,
          link: "/protection-des-données",
        },
        {
          name: `${t('automated_monitoring')}`,
          description: `${t('automate_the_monitoring_of_your_candidates')}`,
          link: "/intelligence-artificielle",
        },
      ],
    },
    {
      name: `${t('use_cases')}`,
      description: `${t('find_out_how_mereos_solutions_will_help')}`,
      link: "/test-de-sélection",
      primaryCategories: [
        {
          name: `${t('standardized_test')}`,
          description: `${t('customized_solutions_for_large_scale_standardized_testing')}`,
          link: "/test-de-sélection",
        },
        {
          name: `${t('higher_education')}`,
          description: `${t('preserve_the_integrity_of_your_students')}`,
          link: "/examens",
        },
        {
          name: "Certification",
          description: `${t('a_reliable_and_effective_method')}`,
          link: "/certification",
        },
      ],
      secondaryCategories: [
        {
          name: `${t('professional_recruitment')}`,
          description: `${t('identify_and_recruit_the_best_talent')}`,
          link: "/test-de-recrutement",
        },
        {
          name: `${t('professional_training')}`,
          description: `${t('get_better_results_with_flexible')}`,
          link: "/formation-professionnelle",
        },
        
      ],
    },
    {
      name: "Entreprise",
      description: `${t('discover_the_people_and_principles_that_govern_our_company')}`,
      link: "/notre-mission",
      primaryCategories: [
        {
          name: `${t('about_us')}`,
          description: `${t('learn_more_about_who_we_are')}`,
          link: "/notre-mission",
        },
        {
          name: "Clients",
          description: `${t('find_out_how_we_ve_helped_our_customers')}`,
          link: "/client",
        },
        // {
        //   name: "Partenaires",
        //   description: "Nous collaborons avec les plus grands noms de l’Éducation et du recrutement pour offrir des prestations uniques.",
        //   link: "/partners",
        // },
        {
          name: "Contact",
          description: `${t('if_you_have_any_questions_or_comments_to_share')}`,
          link: "/contact",
        },
      ],
      secondaryCategories: [
        {
          name: `${t('career')}`,
          description: `${t('do_you_want_to_evolve_in_a_healthy')}`,
          link: "https://angel.co/company/mereos-1",
        },
        {
          name: `${t('costs')}`,
          description: `${t('pricing_designed_for_businesses_of_all_sizes')}`,
          link: "/demo",
        },
      ],
    },
    {
      name: "Ressources",
      description: `${t('discover_best_practices_in_digital_assessment')}`,
      link: "/ressources",
      primaryCategories: [
        {
          name: `${t('white_papers')}`,
          description: `${t('learn_more_about_or_discover_best_practices')}`,
          link: "/ressources",
        },
        {
          name: "Blog",
          description: `${t('discover_the_latest_recruitment_news_and_trends')}`,
          link: "https://mereos.eu/blog/",
        },
        {
          name: `${t('press_room')}`,
          description: `${t('follow_all_the_news_from_mereos')}`,
          link: "https://mereos.eu/blog/newsroom/",
        },
      ],
      secondaryCategories: [
        {
          name: "FAQ",
          description: `${t('discover_the_questions_most_frequently_asked')}`,
          link: "https://mereos.zendesk.com",
        },
        {
          name: `${t('help_support')}`,
          description: `${t('learn_the_basics_that_will_allow_you_to_undertake')}`,
          link: "https://mereos.zendesk.com",
        },
      ],
    },
  ];
  const languages = [
    {
      name: 'french',
      langCode:'fr-FR',
      img: "https://d2lxkizvrhu4im.cloudfront.net/icons/flag-of-france-round.svg"
    },
    {
      name: 'english_uk',
      langCode:'en-US',
      img: "https://d2lxkizvrhu4im.cloudfront.net/icons/flag-of-uk-round.svg"
    },
    {
      name: 'spanish',
      langCode:'es',
      img: "https://d2lxkizvrhu4im.cloudfront.net/icons/flag-of-spain-round.svg"
    },
    {
      name: 'italian',
      langCode:'it',
      img: "https://d2lxkizvrhu4im.cloudfront.net/icons/flag-of-italy-round.svg"
    },
    {
      name: 'portuguese_brazil',
      langCode:'pt',
      img: "https://d2lxkizvrhu4im.cloudfront.net/icons/flag-of-brazil-round.svg"
    },
    {
      name: 'german',
      langCode:'de',
      img: "https://d2lxkizvrhu4im.cloudfront.net/icons/flag-of-germany-round.svg"
    },
    {
      name: 'dutch',
      langCode:'nl',
      img: "https://d2lxkizvrhu4im.cloudfront.net/icons/flag-of-netherlands-round.svg"
    },
  ];

  useEffect(() => {
    const selectedLanguage = languages.filter(language => language.langCode.includes(i18n.language));
    if (selectedLanguage && selectedLanguage.length) {
      setDefaultLangauge({...selectedLanguage[0]});
    } else {
      setDefaultLangauge({...languages[0]})
    }
  }, []);
  

  const handleLanguageChange = (e, language) => {
		e.stopPropagation();
		e.preventDefault();
		i18n.changeLanguage(language.langCode);
		setDefaultLangauge(language);
	};
  return (
    <>
      <header className={`flex flex-row justify-between h-16 overflow-hidden w-full xl:w-11/12 mx-auto font-sans text-sm`}>
        <Link
          to="/"
          className="flex flex-row lg:w-1/6 justify-center items-center px-2"
        >
          <img
            alt="header"
            src="/logo.svg"
            className="w-10 m-0"
          />
          <div className="mx-4 font-extrabold text-2xl">
            mereos
          </div>
        </Link>

        <div className="hidden lg:flex flex-row justify-around items-center w-3/6 font-bold">
          {
            menuBarData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="group border-0 focus:outline-none cursor-pointer"
                >
                  <div className="relative flex flex-row items-center whitespace-nowrap">
                    <Link to={item.link}>
                      {item.name}
                    </Link>
                    <img
                      alt="header"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow-down_yellow_light_icon.svg.svg"
                      className="my-0 ml-1 mr-5"
                    />
                  </div>
                  <div className="absolute hidden group-hover:block z-50 w-full left-0">
                    <div className="flex flex-row justify-evenly overflow-visible bg-white mt-5 p-5 space-y-2 font-normal shadow-xl">
                      <div className="flex flex-col w-3/12">
                        <div className="not-italic font-semibold text-2xl">
                          {item.name}
                        </div>
                        <div className="text-sm text-gray-400 leading-5 mt-2">
                          {item.description}
                        </div>
                      </div>
                      <div className="flex flex-col w-3/12">
                        {
                          item.primaryCategories.map((subCategory, index) => {
                            return (
                              <div key={index} className="flex flex-col mb-4">
                                <div className="flex not-italic font-semibold text-xl">
                                  <Link
                                    to={subCategory.link}
                                    className="text-yellow-400"
                                  >
                                    {subCategory.name}
                                  </Link>
                                  <img
                                    alt="header"
                                    src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow-right-yellow-icon.svg"
                                    className="my-0 ml-2 "
                                  />
                                </div>
                                <div className="text-sm text-gray-400 leading-5">
                                  {subCategory.description}
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                      <div className="flex flex-col w-3/12">
                        {
                          item.secondaryCategories.map((subCategory, index) => {
                            return (
                              <div
                                key={index}
                                className="flex flex-col mb-4"
                              >
                                <div className="flex not-italic font-semibold text-xl">
                                  <Link
                                    to={subCategory.link}
                                    className="text-yellow-400"
                                  >
                                    {subCategory.name}
                                  </Link>
                                  <img
                                    alt="header"
                                    src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow-right-yellow-icon.svg"
                                    className="my-0 ml-2"
                                  />
                                </div>
                                <div className="text-sm text-gray-400 leading-5">
                                  {subCategory.description}
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>

        <div className="w-1/6 hidden xl:flex"></div>

        <div className="hidden lg:flex flex-row items-center justify-center leading-5 font-semibold">
          <div className=" group border-0 focus:outline-none cursor-pointer">
            <div className="relative flex flex-row items-center mr-6">
              {
                defaultLangauge &&
                <img
                  alt={defaultLangauge.name}
                  src={defaultLangauge.img}
                  className="my-0"
                  title={defaultLangauge.name}
                />
              }
              <img
                alt="header"
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow-down_yellow_light_icon.svg.svg"
                className="my-0 ml-2 "
              />
            </div>

            <div className="absolute z-10 hidden group-hover:block">
              <div className="relative right-5 flex flex-col overflow-visible bg-white rounded-lg p-5 space-y-2 font-normal shadow-xl">
                {
                  languages.map((lang, index) => {
                    return lang.langCode !== defaultLangauge.langCode ? (
                      <img
                        key={index}
                        alt={lang.name}
                        src={lang.img}
                        className="my-0 ml-2"
                        title={lang.name}
                        onClick={e => handleLanguageChange(e, lang)}
                      />
                    ) : null
                  })
                }
              </div>
            </div>
          </div>

          <button
            className={`bg-yellow-300 border-none rounded-sm px-5 py-2 whitespace-nowrap focus:outline-none ${classes.getInTouchBtn}`}
            onClick={() => setModalOpen(!isModalOpen)}
          >
            {t('contact_us')}
          </button>
        </div>

        <button
          className="flex flex-col justify-center px-4 pt-8 focus:outline-none"
          onClick={() => setOpen(!isOpen)}
        >
          <img
            src={isOpen ? "https://d2lxkizvrhu4im.cloudfront.net/icons/close_icon.svg" : "https://d2lxkizvrhu4im.cloudfront.net/icons/menu_icon.svg"}
            className="  lg:hidden h-10 cursor-pointer "
            alt="menu icon"
          />
        </button>

        <nav
          className={`absolute right-0 top-14 lg:hidden flex flex-col  w-48 list-none font-bold bg-white text-center px-5 rounded-xl h-full ${
            isOpen ? "flex" : "hidden"
          } `}
        >
          {
            menuBarData.map((item, index) => {
              return (
                <li key={index}>
                  <div className=" group border-0 focus:outline-none cursor-pointer">
                    <div
                      className="flex flex-row items-center"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <Link to={item.link}>{item.name}</Link>
                      <img
                        alt="header"
                        src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow-down_yellow_light_icon.svg.svg"
                        className="my-0 ml-2 "
                      />
                    </div>
                    <div className=" hidden group-hover:block z-50">
                      <div className="flex flex-col overflow-visible bg-white rounded-b-lg p-2 font-normal text-left">
                        {
                          item.primaryCategories
                            .concat(item.secondaryCategories)
                            .map((subCategory, index) => {
                              return (
                                <Link
                                  key={index}
                                  to={subCategory.link}
                                  className="mb-2 text-sm text-gray-400"
                                >
                                  {t(subCategory.name)}
                                </Link>
                              );
                            })
                          }
                      </div>
                    </div>
                  </div>
                </li>
              );
            })
          }
          <li>
            <button
              className={`bg-yellow-300 border-none rounded-sm px-6 py-2 w-46 whitespace-nowrap focus:outline-none ${classes.getInTouchBtn}`}
              onClick={() => setModalOpen(!isModalOpen)}
            >
              {t('contact_us')}
            </button>
          </li>
          <li>
            <div className=" group border-0 focus:outline-none cursor-pointer ">
              <div className=" flex flex-row items-center justify-center ">
                {
                  defaultLangauge &&
                  <img
                    alt={defaultLangauge.name}
                    src={defaultLangauge.img}
                    className="my-0"
                    title={defaultLangauge.name}
                  />
                }
                <img
                  alt="header"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow-down_yellow_light_icon.svg.svg"
                  className="my-0 ml-2 "
                />
              </div>
              <div className=" hidden group-hover:block ">
                <div className="flex flex-col overflow-visible bg-white rounded-lg p-2 items-center justify-center">
                  
                  {
                    languages.map((lang, index) => {
                      return lang.langCode !== defaultLangauge.langCode ? (
                        <img
                          key={index}
                          alt={lang.name}
                          src={lang.img}
                          className="mb-2 mr-5"
                          title={lang.name}
                          onClick={e => handleLanguageChange(e, lang)}
                        />
                      ) : null
                    })
                  }
                </div>
              </div>
            </div>
          </li>
        </nav>
        {
          isModalOpen ? (
            <dialog
              open
              className="contactPopupFormStatic bg-white text-center rounded-lg space-y-2 shadow-xl z-10 top-20 lg:right-10 absolute mx-auto w-11/12   lg:w-2/4"
            >
              <button className="w-full flex flex-row justify-end focus:outline-none absolute top-3 right-3">
                <img
                  alt="header"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/close_icon.svg"
                  onClick={() => setModalOpen(!isModalOpen)}
                  className="cursor-pointer"
                />
              </button>
              <div className={classes.modalcontactform}>
                <ContactForm />
              </div>
              <div className="contact_results_container text-xl font-semibold text-black"></div>
            </dialog>
          ) : (
            ""
          )
        }
      </header>
    </>
  );
};

export default Header;
